// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("image-grid")

function setVh() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}


$(document).on("turbolinks:load", () => {

	$(window).on('resize', () => { setVh(); });
	setVh();

	$('.nav-button', '.bottom-right').click(function(){
		$('.mobile-button').show();
		$('#entourage').hide();
		$('#info').toggle();
	})

	$('.nav-button', '.top-right').click(function(){
		$('.mobile-button').show();
		$('#info').hide();
		$('#entourage').toggle();
	})

	$('.background-grid').click(function(){
		$('.mobile-button').hide();
		$('#info').hide();
		$('#entourage').hide();
	})

	$('.mobile-button').click(function(){
		$('.mobile-button').hide();
		$('#info').hide();
		$('#entourage').hide();
	})


});