$(document).on("turbolinks:load", () => {

	function throttle(func, interval) {
	    var lastCall = 0;
	    return function() {
	        var now = Date.now();
	        if (lastCall + interval < now) {
	            lastCall = now;
	            return func.apply(this, arguments);
	        }
    };
}

	function getRandomInt(min, max) {
	  return Math.floor(Math.random() * (max - min)) + min;
	}

	function generateSamples(gridSize, tileSize, positionIrregularity, baseSize, sizeIrregularity) {

	    var samples = [];
	    var numTiles = Math.ceil(gridSize / tileSize);

	    for(var r = 1; r <= numTiles; r += 1) {
	        for(var c = 1; c <= numTiles; c += 1) {
	            samples.push({
	                x: Math.round(c* tileSize) + getRandomInt(positionIrregularity * -1, positionIrregularity),
	                y: Math.round(r * tileSize) + getRandomInt(positionIrregularity * -1, positionIrregularity),
	                size: getRandomInt(baseSize - sizeIrregularity, baseSize + sizeIrregularity)
	            });
	        }
	    }

	    return samples;
	}

	function getRandomSample(array) {
	    var randomIndex;
	    var coordinates;

	    randomIndex = getRandomInt(0, array.length - 1);
	    coordinates = array[randomIndex];
	    array.splice(randomIndex, 1);

	    return coordinates;
	}


	var tileSize = 900;
	var positionIrregularity = 150;
	var baseSize = 650;
	var sizeIrregularity = 200;

	var newGridSize = Math.ceil(tileSize * Math.ceil(Math.sqrt($('img').length)));
	$('.background-grid').width(newGridSize);
	$('.background-grid').height(newGridSize);

	var gridSamples = generateSamples(newGridSize, tileSize, positionIrregularity, baseSize, sizeIrregularity);
	$('img').each(
		function(){
			var sample = getRandomSample(gridSamples);
			$(this).css('top', sample.y - tileSize/2 - sample.size/2);
			$(this).css('left', sample.x - tileSize/2 - sample.size/2);
			$(this).css('max-width', sample.size);
			$(this).css('max-height', sample.size);
		}
	)

	function scrollGrid(x, y) {
		$('.background-layer').scrollLeft(x);
		$('.background-layer').scrollTop(y);
	}

	var posConversionX = $('.background-layer')[0].scrollWidth/$(window).height();
	var posConversionY = $('.background-layer')[0].scrollHeight/$(window).height();

	$('.background-layer').mousemove(
		throttle(function(e) {
			let posX = e.pageX*posConversionX;
			let posY = e.pageY*posConversionY;
			scrollGrid(posX, posY)
		}, 5)
	);

	// var slidertimer = null
	// $('.control-left').hover(
	// 	function(){
	// 		slidertimer = setInterval(function() {
	// 			$('.background-layer').scrollLeft($('.background-layer').scrollLeft() - (1));
	// 		}, 7);
	// 	},
	// 	function(){
	// 		clearInterval(slidertimer);
	// 	}
	// );
	// $('.control-right').hover(
	// 	function(){
	// 		slidertimer = setInterval(function() {
	// 			$('.background-layer').scrollLeft($('.background-layer').scrollLeft() + (1));
	// 		}, 7);
	// 	},
	// 	function(){
	// 		clearInterval(slidertimer);
	// 	}
	// );

});